<template>

   <div>
      <div class="card-body">
         <p class="login-box-msg">Informe suas credenciais para criar a conta</p>

         <form @submit.prevent="registerUser">

            <div v-if="Object.keys(errors).length">
               <div v-for="(field, index) in Object.keys(errors)" :key="index">
                  <div v-for="(error, indx) in errors[field] || []" :key="indx" class="alert alert-danger" role="alert">
                     {{ error }}
                  </div>
               </div>
            </div>

            <div class="input-group mb-3">
               <input type="text" class="form-control" placeholder="Nome" v-model="name" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-user"></span>
                  </div>
               </div>
            </div>
            <div class="input-group mb-3">
               <input type="email" class="form-control" placeholder="Email" v-model="email" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-envelope"></span>
                  </div>
               </div>
            </div>
            <div class="input-group mb-3">
               <input type="password" class="form-control" placeholder="Senha" v-model="password" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-lock"></span>
                  </div>
               </div>
            </div>
            <div class="row">
               <!-- /.col -->
               <div class="col-12">
                  <button
                     type="submit"
                     class="btn btn-outline-primary btn-block"
                     :disabled="isLoading">
                     <b>Registre-se</b>
                  </button>
               </div>
               <!-- /.col -->
            </div>
         </form>

         <p class="mb-1 mt-4">
            <router-link :to="{name: 'login'}" class="text-gray-dark">Já possui uma conta, clique aqui.</router-link>
         </p>
      </div>
      <!-- /.card-body -->
   </div>

</template>

<script>
import { useToast } from 'vue-toastification'
import { mapActions } from "vuex";

 export default {
     name: 'Register',
    setup() {
       const toast = useToast()

       return { toast }
    },
    data () {
        return {
           name: '',
           email: '',
           password: '',
           isLoading: false,
           errors: []
        }
    },
    methods: {
        ...mapActions(['register']),
        async registerUser () {
           this.resetErrors();
           this.isLoading = true;

           try {
               const user = {
                  displayName: this.name,
                  email: this.email,
                  password: this.password
               }

               await this.register(user);
               this.toast.success(this.$t("register_success"));
           } catch (error) {
              const { status } = error.response;
              const errorResponse = error.response.data.errors;

              if (status === 422)
                 this.errors = Object.assign(this.errors, errorResponse)

              this.toast.error(this.$t("register_error"));
           } finally {
              this.isLoading = false;
           }
        },
       resetErrors () {
          this.errors = []
       }
    }
 }
</script>
